<template>
  <!-- 订单支付 -->
  <div id="app">
    <div class="wechatpay">
      <div class="pay-logo clearfix">
        <router-link to="/"
          ><img class="logo" src="@/assets/img/pay-logo.png"
        /></router-link>
        <ul>
          <li>
            <router-link to="/center/imformation"
              >{{ userInfo.username }}
            </router-link>
          </li>
          <li>
            <router-link to="/center/order">我的订单</router-link>
          </li>
          <li>
            <span @mouseenter="enter()" @mouseleave="leave()">支付帮助</span>
            <div v-if="tipsshow" class="pay-qes-con">
              <img v-if="qr" :src="qr.imageUrl" />
              <h2>扫描微信二维码 课程和网站疑问</h2>
              <p>针对课程有疑问? 购买时遇到问题?</p>
            </div>
          </li>
        </ul>
      </div>
      <div class="pay-number">
        <p>订单提交成功，请尽快付款！订单号：{{ orderInfo.orderNumber }}</p>
        <h2>
          应付金额<span>{{ orderInfo.rmbPayAmount }}</span
          >元
        </h2>
      </div>
      <div class="pay-card">
        <div class="pay-card-tit">
          <span>{{ payData.payTypeDesc || "" }}</span>
          <template v-if="countDown > 0">
            <span v-if="payData.action === 'showLink'" style="color: #39495c">
              距离二维码过期还剩下
              <label>{{ countDown }} </label>
              秒，过期后请刷新页面重新获取二维码
            </span>
          </template>
        </div>
        <div v-if="payType === 'wechat' && countDown > 0" class="pay-teach">
          <div class="tec-le">
            <img :src="payData.content" class="code" />
            <p>
              <img class="icon" src="@/assets/img/scan-icon.png" />
              请使用微信扫一扫
              <br />扫描二维码支付
            </p>
          </div>
          <div class="tec-rg">
            <img class="code" src="@/assets/img/wechat-pay-img.png" />
          </div>
        </div>
        <div class="other-way" @click="otherpay">
          &lt;&nbsp;选择其他支付方式
        </div>
      </div>
      <div class="copyright">
        Copyright © 2018-2023 浙ICP备19052770号 杭州世连华科技有限公司
      </div>
    </div>

    <!-- 等待完成支付弹窗 -->
    <div
      v-if="payData.action === 'showForm' && dialogFormVisible"
      class="submit-con"
    >
      <div class="box zhuan-box pay-box">
        <img class="code" src="@/assets/img/wait-icon.png" />
        <h3>等待您完成支付...</h3>
        <p>请在支付页面继续完成支付</p>
        <button @click="paymentComplete">支付完成</button>
        <p @click="paymentMethodSelect">
          <img src="@/assets/img/refresh-icon.png" />重新选择支付方式
        </p>
        <img
          class="close"
          src="@/assets/img/close-icon.png"
          @click="dialogFormVisible = false"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { navigateBack } from "../../utils/common";
import { getCourseBanner, getOrder, paymentCheck } from "../../service";
import { mapState } from "vuex";

export default {
  data() {
    return {
      tipsshow: false,
      dialogFormVisible: true,
      payData: "",
      orderId: "",
      orderInfo: "",
      countDown: 60,
      qr: null,
      cutdownTimer: null,
      checkPaymentTimer: null,
      payType: "",
    };
  },
  watch: {},
  created() {},
  computed: mapState(["userInfo"]),
  mounted() {
    this.payType = this.$route.query.payType;
    console.log(this.userInfo);
    let orderId = this.$route.query.orderId;
    if (!orderId) {
      this.$message.error("订单数据错误，请重新下单");
      navigateBack(-1, 2000);
      return;
    }
    this.orderId = orderId;
    //订单数据
    getOrder(this.orderId).then((res) => {
      if (res.success) {
        this.orderInfo = res.result;
        if (this.orderInfo.code) {
          this.$message.error(this.orderInfo.msg || "系统错误-1000003");
          this.$router.replace("/confirm");
          return;
        }
      }
    });

    //支付数据
    this.payData = JSON.parse(localStorage.getItem("payData"));
    console.log(this.payData);
    if (this.payData.code) {
      this.$message.error(this.payData.msg || "系统错误-1000002");
      this.$router.replace("/confirm");
      return;
    }

    this.setPayType();
    if (this.payData.action === "showForm") {
      let route = this.$router.resolve({ path: `/payform` });
      window.open(route.href, "_blank");
    }
    if (this.$route.query.payType == "wechat") {
      this.startCountDown();
    }

    // 轮询支付结果
    this.checkPaymentTimer = setInterval(() => {
      this.paymentComplete();
    }, 2000);
  },
  methods: {
    showTips() {
      this.tipsshow = !this.tipsshow;
    },

    enter() {
      this.tipsshow = !this.tipsshow;
      // 拿二维码图片
      getCourseBanner(4)
        .then((res) => {
          console.log(res);
          if (!res.result.code) {
            this.qr = res.result[0];
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    leave() {
      this.tipsshow = !this.tipsshow;
    },

    otherpay() {
      this.$router.push({
        path: "/orderpay",
        query: { orderId: this.orderId },
      });
      // window.history.back();
    },
    setPayType() {
      if (this.payData) {
        switch (this.$route.query.payType) {
          case "alipay":
            this.payData.payTypeDesc = "支付宝";
            break;
          case "wechat":
            this.payData.payTypeDesc = "微信支付";
            break;
          case "huabei":
            this.payData.payTypeDesc = "花呗分期";
            break;
          case "paypal":
            this.payData.payTypeDesc = "PayPal";
            break;
        }
      }
    },
    paymentComplete() {
      paymentCheck(this.orderId)
        .then((res) => {
          if (res.result) {
            this.$router.push("/pay/success");
          }
          // console.log("paymentCheck success", res);
        })
        .catch((err) => {
          console.log("paymentCheck fail", err);
        });
    },
    paymentMethodSelect() {
      this.$router.back();
    },
    startCountDown() {
      this.cutdownTimer = setInterval(() => {
        if (this.countDown > 0) {
          this.countDown = this.countDown - 1;
        } else {
          window.location.reload();
        }
      }, 1000);
    },
  },
  beforeDestroy() {
    clearInterval(this.cutdownTimer);
    clearInterval(this.checkPaymentTimer);
    this.cutdownTimer = null;
    this.checkPaymentTimer = null;
  },
};
</script>

<style>
</style>
